<template>
  <div>
    <b-img
      class="urunlerImg mt-0"
      :src="contact.img"
      fluid
      alt="Responsive image"
    />
    <!--Form -->
    <div class="cardForm">
      <b-row>
        <b-col md="1"></b-col>
        <b-col md="4">
          <b-card
            v-if="AdressData.length > 0"
            class="contactCard mt-3 ml-2 mr-1"
          >
            <h2>{{ AdressData[0].location }}</h2>

            <b-card-text>{{ AdressData[0].address }}</b-card-text>
            <b-card-text>
              <a href="tel:+903805020135">
                <feather-icon icon="PhoneIcon" />{{ AdressData[0].phone }}
              </a></b-card-text
            >
            <b-card-text>
              <a href="mailto:info@altekaforge.com">
                <feather-icon icon="MailIcon" /> {{ AdressData[0].email }}
              </a></b-card-text
            >
            <br />
            <hr />
            <br />
            <h2>{{ AdressData[1].location }}</h2>

            <b-card-text>{{ AdressData[1].address }}</b-card-text>
            <b-card-text>
              <a href="tel:+903805020135">
                <feather-icon icon="PhoneIcon" /> {{ AdressData[1].phone }}
              </a></b-card-text
            >
            <b-card-text>
              <a href="mailto:info@altekaforge.com">
                <feather-icon icon="MailIcon" /> {{ AdressData[1].email }}
              </a></b-card-text
            >
          </b-card>
        </b-col>
        <b-col md="6">
          <br />
          <b-card class="contactCard mt-2">
            <b-col md="12"
              ><h2>{{ getLocalizedText("contactTitle") }}</h2></b-col
            >
            <hr />

            <b-col md="12">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="mc-first-name">
                      <h5>{{ getLocalizedText("firstNameLabel") }}</h5>
                      <b-form-input
                        v-model="post.userName"
                        id="mc-first-name"
                        @click="changeBackgroundColor"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="mc-last-name">
                      <h5>{{ getLocalizedText("lastNameLabel") }}</h5>
                      <b-form-input
                        v-model="post.userLastName"
                        id="mc-last-name"
                        @click="changeBackgroundColor"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="v-mobile">
                      <h5>{{ getLocalizedText("phoneLabel") }}</h5>
                      <b-form-input
                        v-model="post.mobile"
                        id="v-mobile"
                        type="number"
                        @click="changeBackgroundColor"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="mc-email">
                      <h5>{{ getLocalizedText("emailLabel") }}</h5>
                      <div class="form-label-group">
                        <b-form-input
                          v-model="post.email"
                          id="mc-email"
                          type="email"
                          @click="changeBackgroundColor"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <div class="form-label-group">
                      <h5>{{ getLocalizedText("aboutYouLabel") }}</h5>
                      <b-form-textarea
                        id="textarea"
                        rows="3"
                        v-model="post.aboutYou"
                        @click="changeBackgroundColor"
                      />
                    </div>
                  </b-col>

                  <!-- submit -->
                  <b-col
                    class="d-flex justify-content-center align-items-center"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="submit"
                      variant="outline-secondary"
                      id="submitForm"
                    >
                      {{ getLocalizedText("submitLabel") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col> </b-card
          ><br
        /></b-col>
        <b-col md="1"></b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BImg,
  BCardTitle,
  BJumbotron,
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import axios from "axios";
export default {
  components: {
    BRow,
    BCol,
    BImg,
    BJumbotron,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BCardTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      post: {
        userName: "",
        userLastName: "",
        mobile: "",
        email: "",
        aboutYou: "",
      },
      contact: {
        id: 5,
        img: require("@/assets/images/banner/contact.jpg"),
        title: "BİZE ULAŞIN;",
      },
      AdressData: [],
    };
  },
  created() {
    this.getCommunications();
  },
  methods: {
    getCommunications() {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(`/api/Informations/GetListByLanguage?language=${languageCode}`)
        .then((result) => {
          if (result.data.success) {
            this.AdressData = result.data.data;
            //   console.log(this.AdressData)
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
    onSubmit() {
      const newSliderInfo = {
        email: this.post.email,
        firstName: this.post.userName,
        lastName: this.post.userLastName,
        phone: this.post.mobile,
        message: this.post.aboutYou,
      };
      axios
        .post("/api/Communications/Create", newSliderInfo)
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("Mesajınız Gönderildi", {
              title: `Form`,
              variant: "success",
            });
            this.post.userName = "";
            this.post.userLastName = "";
            this.post.mobile = "";
            this.post.email = "";
            this.post.aboutYou = "";
          } else {
            this.$bvToast.toast("Mesajınız Gönderilemedi!", {
              title: `Form`,
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Güncelleme Hatası", error);
        });
    },
    changeBackgroundColor() {
      document.activeElement.style.backgroundColor = "white";
    },
    getLocalizedText(key) {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      const translations = {
        contactTitle: languageCode === 2 ? "CONTACT" : "BİZE ULAŞIN",
        firstNameLabel: languageCode === 2 ? "First Name" : "İsim",
        lastNameLabel: languageCode === 2 ? "Last Name" : "Soyisim",
        phoneLabel: languageCode === 2 ? "Mobile" : "Telefon",
        emailLabel: languageCode === 2 ? "Email" : "E-posta",
        aboutYouLabel: languageCode === 2 ? "About You" : "Hakkınızda",
        submitLabel: languageCode === 2 ? "SEND" : "GÖNDER",
      };

      return translations[key] || "";
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");
h1 {
  color: white;
}
.urunlerImg {
  width: 100%;
  height: 400px;
  z-index: 1;
}
@media (max-width: 1000px) {
  .urunlerImg {
    width: 100%;
    height: auto;
  }
}
a {
  color: rgb(0, 0, 0);
}
.cardForm {
  font-family: "Raleway", sans-serif;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

h2 {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 40px;
  color: white;
}
h5 {
  color: white;
}
.form-label-group {
  font-size: 20px;
  color: white !important;
}
.contactCard {
  background-color: #656b7ba6;
  padding: 10px;
  border: none !important;
  color: white;
}
#mc-last-name {
  border: 1px solid white !important;
  border-radius: 0%;
  background-color: transparent;
}

#textarea {
  border: 1px solid white !important;
  border-radius: 0%;
  background-color: transparent;
}
#v-mobile {
  border: 1px solid white !important;
  border-radius: 0%;
  background-color: transparent;
}
#mc-first-name {
  border: 1px solid white !important;
  border-radius: 0%;
  background-color: transparent;
}
#mc-email {
  border: 1px solid white !important;
  border-radius: 0%;
  background-color: transparent;
}
#submitForm {
  border: 1px solid white !important;
  padding: 15px 50px;
  font-size: 18px;
  color: black;
  background-color: white;
  border-radius: 0%;
  transition: background-color 0.3s, color 0.3s;
}
#submitForm:hover {
  background-color: rgba(
    128,
    128,
    128,
    0
  ); /* Hover olduğunda arka plan rengi */
  color: white; /* Hover olduğunda metin rengi */
  cursor: pointer; /* Fare imleci türü */
}
</style>
