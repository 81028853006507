<template>
  <div class="backgroundStyle">
    <!-- NavBar-->
    <navbar />
    <!-- contact -->
    <contact />

  </div>
</template>
<script>
import Navbar from "./home/components/Navbar.vue";
import footerVue from "./home/components/footer.vue";
import contact from "./Contact/contact.vue";
export default {
  components: {
    Navbar,
    footerVue,
    contact,
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Dancing+Script&family=Prompt:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
.backgroundStyle {
  background-color: #8ea2b486 !important;
}
</style>
